import { index } from '../../services/reports/reports'

export const reports = {

  namespaced: 'reports',

  state: {
    data: {
      integrations: {
        errors: []
      },
      orders: [],
      financial: {},
      orders_daily: {},
      from: null,
      to: null
    },
    from: null,
    to: null,
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, data) {
      index(data)
        .then(response => {
          commit('setData', response.data)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }

  },

  mutations: {

    setData (state, data) {
      state.data = {
        from: data.from,
        to: data.to,
        financial: data.reports.financial,
        orders_daily: data.reports.orders_daily,
        integrations: data.reports.integrations,
        orders: data.reports.orders
      }
    },

    setDetails (state, category) {
      state.details = category
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
