<template>
  <div class="products">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-10">
            <h1 class="fw-600 fs-24 mb-0">Produtos</h1>
          </div>
          <div class="col-md-2 text-right text-end pt-2">
            <router-link class="btn btn-blue" to="/products/create">
              <i class="fas fa-plus mr-2"></i>
              Novo Produto
              </router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Nome</th>
                <th>Preço de Venda</th>
                <th>Quantidade</th>
                <th>Variações</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in products.data" :key="product.id" class="c-black">
                <td class="align-middle">
                  <router-link :to="'./products/' + product.id" class="btn btn-link c-black body-table">
                    {{ product.id }}
                  </router-link>
                </td>
                <td class="align-middle d-flex justify-content-center">
                  <router-link :to="'./products/' + product.id">
                    <img :src="product.image_thumbnail ? product.image_thumbnail : '/img/no_product.png'" class="rounded-circle border" alt="" height="40">
                  </router-link>
                </td>
                <td class="align-middle">
                  <router-link :to="'./products/' + product.id" class="btn btn-link c-black body-table">
                    {{ product.title }}
                  </router-link>
                </td>
                <td class="align-middle body-table">{{ product.price | money }}</td>
                <td class="align-middle body-table">{{ product.stock_quantity }}</td>
                <td class="align-middle body-table">{{ product.variants_count }}</td>
                <td class="align-middle body-table px-0">
                  <button class="btn ml-4" @click="openDeleteModal(product.id)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginateComponent :source="pagination" @navigate="navigate"/>
      </div>
    </div>
    <LoadingComponent :loading="loading || !products.data" />

    <!-- Modal excluir produto -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row text-white">
              <div class="col-12 d-flex justify-content-end mb-3">
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="col-8 pb-2 pt-1 pl-3">
                <label class="form-control-label h4">Deseja excluir o produto?</label>
              </div>
              <div class="col-4 text-right">
                <button type="button" class="btn btn-outline-danger" @click="deleteProduct(product_id)">Excluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexProductsPage',

  components: {
    LoadingComponent,
    PaginateComponent
  },

  data: function () {
    return {
      product_id: null
    }
  },

  computed: mapState({
    products: state => state.products.data,
    pagination: state => state.products.pagination,
    loading: state => state.products.loading
  }),

  methods: {

    ...mapActions('products', [
      'index',
      'destroy'
    ]),

    openDeleteModal: function (id) {
      this.product_id = id
      $('#deleteModal').modal('show')
    },

    deleteProduct: function (id) {
      this.destroy(id)
      $('#deleteModal').modal('hide')
    },

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search, filter: this.filter })
      $('#searchModal').modal('hide')
    },

    navigate (page) {
      this.searchWithPaginate(page)
    }

  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
