<template>
  <div class="customers">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-4">
            <h1 class="fw-600 fs-24 mb-0">Clientes </h1>
          </div>
          <div class="col mt-2">
            <!-- <button class="btn btn-warning" v-if="download.length == 0" :disabled="load === true" @click="exportExcel()">
              {{ load === true ? 'Extraindo...' : 'Extrair Relatório' }}
            </button>
            <JsonExcel
              v-else
              class="btn btn-warning"
              :data="download"
              type="xls"
              worksheet="Download"
              name="relatório Clientes"
              data-dismiss="modal"
            /> -->
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bcustomered">
            <thead>
              <tr class="t-head">
                <th scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    <small>#</small>
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    Nome
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th scope="col" class="pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    E-mail
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th scope="col" class="pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    CPF
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  Localização
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="customer in customers.data" :key="customer.id">
                <td class="align-middle body-table p-0">
                  <router-link :to="'./customers/' + customer.id" class="btn btn-link c-black nowrap">
                    <p class="mb-0"># {{ customer.id}}</p>
                  </router-link>
                </td>
                <td class="align-middle body-table p-0">
                  <router-link :to="'./customers/' + customer.id" class="btn btn-link c-black nowrap">
                    {{ customer.name.substr(0, 30) }}...
                  </router-link>
                </td>
                <td class="align-middle body-table p-0">
                  <router-link :to="'./customers/' + customer.id" :title="customer.name" class="btn btn-link c-black nowrap">
                    {{ customer.email }}
                  </router-link>
                </td>
                <td class="align-middle body-table p-0">
                  <router-link :to="'./customers/' + customer.id" :title="customer.name" class="btn btn-link c-black nowrap">
                    {{ customer.details ? '***.***.***-**' : ''}}
                  </router-link>
                </td>
                <td class="align-middle body-table p-0">
                  <router-link :to="'./customers/' + customer.id" :title="customer.name" class="btn btn-link c-black nowrap">
                    {{ customer.address ? customer.address.city : '' }} - {{ customer.address ? customer.address.state : '' }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginateComponent :source="pagination" @navigate="navigate"/>
      </div>
    </div>
    <LoadingComponent :loading="loading || !customers.data" />

    <!-- Modal de Pesquisa -->
    <div class="modal fade" tabindex="-1" id="searchModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filtrar Listagem</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <input type="text" placeholder="Digite o que deseja filtrar" v-model="search" class="form-control my-4 mx-3">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-warning" @click="index({ page: 1, search, filter})" data-dismiss="modal">Filtrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexcustomerPage',

  components: {
    LoadingComponent,
    PaginateComponent
  },

  computed: mapState({
    customers: state => state.customers.data,
    loading: state => state.customers.loading,
    pagination: state => state.customers.pagination
  }),

  data: function () {
    return {
      search: '',
      load: false
    }
  },

  methods: {

    ...mapActions('customers', [
      'index'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search })
      $('#searchModal').modal('hide')
    },

    navigate (page) {
      this.searchWithPaginate(page)
    }

  },

  mounted () {
    this.searchWithPaginate(1)
    $(document).ready(function () {
      $('[data-toggle="popover"]').popover()
    })
  }

}

</script>
