import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth/auth'
import { articles } from './articles/articles'
import { carts } from './carts/carts'
import { categories } from './categories/categories'
import { colors } from './colors/colors'
import { coupons } from './coupons/coupons'
import { integrations } from './integrations/integrations'
import { logs } from './logs/logs'
import { materials } from './materials/materials'
import { customers } from './customers/customers'
import { orders } from './orders/orders'
import { products } from './products/products'
import { recurrences } from './recurrences/recurrences'
import { suppliers } from './suppliers/suppliers'
import { bankAccounts } from './bank-accounts/bank-accounts'
import { reports } from './reports/reports'
import { params } from './params/params'
import { preferences } from './preferences/preferences'
import { sessions } from './sessions/sessions'
import { shippings } from './shippings/shippings'
import { blogs } from './blogs/blogs'
import { blogCategories } from './blogs/categories'
import { sizes } from './sizes/sizes'
import { variants } from './variants/variants'
import { users } from './users/users'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    articles,
    bankAccounts,
    carts,
    categories,
    coupons,
    integrations,
    customers,
    orders,
    products,
    recurrences,
    reports,
    params,
    preferences,
    sessions,
    shippings,
    logs,
    blogs,
    blogCategories,
    suppliers,
    colors,
    materials,
    sizes,
    users,
    variants
  }
})
