<template>
  <div>
    <i :class="[
        'fas',
        'fa-dot-circle',
        'mr-2',
        order_status == 1 ? 'text-muted' : '',
        order_status == 2 ? 'text-warning' : '',
        order_status == 3 ? 'text-success' : '',
        order_status == 4 ? 'text-danger' : '',
      ]"></i>{{ labelText() }}
  </div>
</template>

<script>

export default {

  props: ['order_status'],

  methods: {
    labelText () {
      let text
      if (this.order_status === undefined) {
        text = 'N/A'
        return text
      }
      switch (this.order_status) {
        case '1':
          text = 'Rascunho'
          break
        case '2':
          text = 'Aguardando'
          break
        case '3':
          text = 'Autorizado'
          break
        case '4':
          text = 'Cancelado'
          break
        default:
          text = 'N/A'
      }
      return text
    }
  }

}

</script>
