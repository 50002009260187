<template>
  <div id="app">
    <NavbarComponent v-if="token" />
    <SidebarComponent v-if="token" />
    <ContentComponent />
  </div>
</template>

<script>

import SidebarComponent from './components/layout/SidebarComponent'
import ContentComponent from './components/layout/ContentComponent'
import NavbarComponent from './components/layout/NavbarComponent'
import { mapState, mapActions } from 'vuex'

export default {

  components: {
    SidebarComponent,
    ContentComponent,
    NavbarComponent
  },

  computed: mapState({
    auth: state => state.auth
  }),

  data () {
    return {
      token: localStorage.token
    }
  },

  methods: {
    ...mapActions('preferences', [
      'getTheme'
    ])
  },

  mounted () {
    this.getTheme()
  }

}

</script>
