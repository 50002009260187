<template>
  <div class="categories">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Categorias</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Nova Categoria
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Categoria Pai</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="category in categories.data" :key="category.id">
                <td class="align-middle body-table">
                    {{ category.id }}
                </td>
                <th class="align-middle body-table">
                    {{ category.description }}
                </th>
                <td class="align-middle body-table">
                  {{ category.slug }}
                </td>
                <td class="align-middle body-table">
                  {{ category.parent ? category.parent.description : '-' }}
                </td>
                <td class="align-middle body-table">
                  <button class="btn mr-2" data-toggle="modal" @click="openUpdateModal(category)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn" data-toggle="modal" @click="openDeleteModal(category)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !categories.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (category.id ? 'Editar Categoria' : 'Cadastrar Categoria')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="category.description" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">URL Slug:</label>
                    <input type="text" class="form-control" v-model="category.slug" required>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label">Vincular a um cupom:</label>
                    <select class="form-control" v-model="category.coupon_id" required>
                      <option :value="null"> - Selecione - </option>
                      <option v-for="coupon in coupons.data" :key="coupon.id" :value="coupon.id">{{ coupon.description }}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Vincular com outra categoria:</label>
                    <select class="form-control" v-model="category.parent_id" required>
                      <option :value="null"> - Selecione - </option>
                      <option v-for="category in categories.data" :key="category.id" :value="category.id">{{ category.description }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateCategory()" v-if="category.id">Editar Categoria</button>
            <button type="button" class="btn btn-success" @click="addCategory()" v-else>Cadastrar Categoria</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir Categoria</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o categoria?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteCategory()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'IndexCategoriesPage',

  components: {
    LoadingComponent
  },

  computed: mapState({
    categories: state => state.categories.data,
    coupons: state => state.coupons.data,
    loading: state => state.categories.loading
  }),

  data: function () {
    return {
      category: {
        description: null,
        slug: null,
        parent_id: null,
        coupon_id: null
      },
      destroy_category: null
    }
  },

  methods: {

    ...mapActions('categories', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    ...mapActions('coupons', [
      'getCoupons'
    ]),

    addCategory: function () {
      this.store(this.category)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.category = {
        description: null,
        slug: null,
        coupon_id: null,
        parent_id: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (category) {
      this.category = category
      $('#CreateModal').modal()
    },

    openDeleteModal: function (category) {
      this.destroy_category = category
      $('#openDeleteModal').modal()
    },

    updateCategory: function () {
      this.update({ id: this.category.id, data: this.category })
      $('#CreateModal').modal('hide')
    },

    deleteCategory: function () {
      this.destroy(this.destroy_category.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
    this.getCoupons({
      page: 1,
      name: null
    })
  }

}

</script>
