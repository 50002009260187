import { show } from '../../services/logs/logs'

export const logs = {

  namespaced: 'logs',

  state: {
    data: [],
    loading: false,
    error: null
  },

  actions: {

    companyOrderLogs ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setData', response.data)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }
  },

  mutations: {

    setData (state, logs) {
      state.data = logs
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
