import Vue from 'vue'
import { index, show, update } from '../../services/customers/customers'

export const customers = {

  namespaced: 'customers',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, data) {
      index(data.page, data.search)
        .then(response => {
          commit('setData', response.data.customers)
          commit('setPagination', response.data.customers)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    show ({ state, commit }, id) {
      show(id)
        .then(response => {
          commit('setDetails', response.data.customer)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit }, data) {
      update(data.id, data)
        .then(response => {
          commit('setDetails', response.data.customer)
          commit('setLoading', false)
          Vue.toasted.success('Cliente atualizado com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar cliente!')
        })
    }

  },

  mutations: {

    setData (state, customers) {
      state.data = customers
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, customer) {
      state.details = customer
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
