<template>
    <div class="card mb-3 h-100">
        <div class="card-left">
            NFe
        </div>
        <div class="card-right break">
            <p>Status: {{ invoices[0].status }} </p>
            <p>Número: {{ invoices[0].number }}</p>
            <p>Chave: {{ invoices[0].key }}</p>
            <p>Ultima Atualização: {{ invoices[0].updated_at | date }}</p>
        </div>
    </div>
</template>

<script>
export default {

  props: ['invoices']
}
</script>
