<template>
    <div>
        <p v-if="payment_status[0].status == 1">
          <i class="fas fa-dot-circle text-warning mr-2"></i> Pendente
        </p>
        <p v-else-if="payment_status[0].status == 2">
          <i class="fas fa-dot-circle text-success mr-2"></i> Autorizado
        </p>
        <p v-else-if="payment_status[0].status == 3">
          <i class="fas fa-dot-circle text-danger mr-2"></i> Recusado
        </p>
        <p v-else-if="payment_status[0].status == 4">
          <i class="fas fa-dot-circle text-danger mr-2"></i> Cancelado
        </p>
        <p v-else>
          <i class="fas fa-bug text-danger mr-2"></i> Erro
        </p>
  </div>
</template>

<script>

export default {

  props: ['payment_status']

}

</script>
