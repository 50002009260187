import Http from '../http'

export const index = (page, product, filter = {}) => {
  let url = `/products/${product}/variants?page=${page}`
  url = filter.name ? url + `&name=${filter.name}` : url
  url = filter.param_color_id ? url + `&param_color_id=${filter.param_color_id}` : url
  url = filter.param_size_id ? url + `&param_size_id=${filter.param_size_id}` : url
  url = filter.param_material_id ? url + `&param_material_id=${filter.param_material_id}` : url
  return Http.get(url, { headers: { Authorization: localStorage.token } })
}
export const store = (product, data) => Http.post(`/products/${product}/variants`, { data }, { headers: { Authorization: localStorage.token } })
export const update = (product, id, data) => Http.patch(`/products/${product}/variants/${id}`, { data }, { headers: { Authorization: localStorage.token } })
export const updatePrice = (product, data) => Http.patch(`/products/${product}/variants/update-prices`, { data }, { headers: { Authorization: localStorage.token } })
export const destroy = (product, id) => Http.delete(`/products/${product}/variants/${id}`, { headers: { Authorization: localStorage.token } })
