import { index, store, update, destroy } from '../../services/integrations/integrations'
import Vue from 'vue'
export const integrations = {

  namespaced: 'integrations',

  state: {
    data: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      index(page, name)
        .then(response => {
          commit('setData', response.data.integrations)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit }, data) {
      store(data)
        .then(response => {
          commit('setDetails', response.data.integration)
          commit('setLoading', false)
          // se a integração precisar de uma ação externa
          if (response.data.action_url) {
            // abre uma nova guia
            window.open(response.data.action_url, '_blank')
          }
          Vue.toasted.success('Integração cadastrada com sucesso!')
          setTimeout(() => {
            window.location.reload(1)
          }, 1200)
        })
        .catch(error => {
          Vue.toasted.error('Erro ao cadastrar integração')
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit }, { id, data }) {
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.integration)
          commit('setLoading', false)
          Vue.toasted.success('Integração atualizada com sucesso')
          setTimeout(() => {
            window.location.reload(1)
          }, 1200)
        })
        .catch(error => {
          Vue.toasted.error('Erro ao atualizar integração')
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    destroy ({ commit }, data) {
      destroy(data)
        .then(response => {
          commit('setDetails', response.data.integration)
          commit('setLoading', false)
          Vue.toasted.success('Integração excluída com sucesso')
          setTimeout(() => {
            window.location.reload(1)
          }, 1200)
        })
        .catch(error => {
          Vue.toasted.error('Erro ao excluir integração')
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }

  },

  mutations: {

    setData (state, integrations) {
      state.data = integrations
    },

    setDetails (state, integration) {
      state.details = integration
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
