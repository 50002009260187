import {
  getCoupons,
  getProducts,
  getColors,
  getMaterials,
  getSizes,
  getBanks,
  getCategories,
  getUserTypes,
  showCategory
} from '../../services/params/params'

// import Vue from 'vue'

export const params = {

  namespaced: 'params',

  state: {
    data: {
      user_types: [],
      banks: [],
      products: [],
      categories: [],
      colors: [],
      materials: [],
      sizes: [],
      coupons: []
    },
    details: {},
    loading: false,
    error: null
  },

  actions: {

    getCoupons ({ state, commit }, { page }) {
      commit('setLoading', true)
      getCoupons(page)
        .then(response => {
          commit('setCoupons', response.data.coupons)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getBanks ({ state, commit }) {
      commit('setLoading', true)
      getBanks()
        .then(response => {
          commit('setBanks', response.data.banks)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getProducts ({ state, commit }, status = null) {
      commit('setLoading', true)
      getProducts(status)
        .then(response => {
          commit('setProducts', response.data.products)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getUserTypes ({ state, commit }) {
      commit('setLoading', true)
      getUserTypes()
        .then(response => {
          commit('setUserTypes', response.data.user_types)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getColors ({ state, commit }) {
      commit('setLoading', true)
      getColors()
        .then(response => {
          commit('setColors', response.data.colors)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getMaterials ({ state, commit }) {
      commit('setLoading', true)
      getMaterials()
        .then(response => {
          commit('setMaterials', response.data.materials)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getSizes ({ state, commit }) {
      commit('setLoading', true)
      getSizes()
        .then(response => {
          commit('setSizes', response.data.sizes)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getCategories ({ state, commit }, { page }) {
      commit('setLoading', true)
      getCategories(page)
        .then(response => {
          commit('setCategories', response.data.categories)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    showCategory ({ state, commit }, id) {
      commit('setLoading', true)
      showCategory(id)
        .then(response => {
          commit('setData', response.data)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }
  },

  mutations: {

    setData (state, data) {
      state.data = {
        data,
        ...state.data
      }
    },

    setCoupons (state, data) {
      state.data.coupons = data
    },

    setBanks (state, data) {
      state.data.banks = data
    },

    setProducts (state, data) {
      state.data.products = data
    },

    setColors (state, data) {
      state.data.colors = data
    },

    setMaterials (state, data) {
      state.data.materials = data
    },

    setSizes (state, data) {
      state.data.sizes = data
    },

    setUserTypes (state, data) {
      state.data.user_types = data
    },

    setCategories (state, data) {
      state.data.categories = data
    },

    setDetails (state, category) {
      state.details = category
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}
}
