import { index, store, update, destroy } from '../../services/carts/carts'
import Vue from 'vue'
export const carts = {

  namespaced: 'carts',

  state: {
    data: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.carts)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.cart)
          commit('setLoading', false)
          Vue.toasted.success('Carrinho cadastrada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a Carrinho!')
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.cart)
          commit('setLoading', false)
          Vue.toasted.success('Carrinho editada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a Carrinho!')
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      destroy(data)
        .then(response => {
          commit('setDetails', response.data.cart)
          commit('setLoading', false)
          Vue.toasted.success('Carrinho excluída com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a Carrinho!')
        })
    }

  },

  mutations: {

    setData (state, carts) {
      state.data = carts
    },

    setDetails (state, cart) {
      state.details = cart
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
