<template>
  <div class="bankAccounts px-5 py-5">
    <div class="row">
      <div class="col">
        <h1 class="fw-600 fs-24 mb-0">Editar Conta Bancária</h1>
        <h6 class="fw-400">{{ bankAccount.title }}</h6>
      </div>
    </div>
    <hr>
    <FormBankAccounts :_bankAccount="bankAccount" @update="update" />
    <LoadingComponent :loading="loading || !bankAccount.id" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import FormBankAccounts from './FormBankAccounts'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'ShowBankAccountsPage',

  components: {
    LoadingComponent,
    FormBankAccounts
  },

  data () {
    return {
      bankAccount: {}
    }
  },

  watch: {
    details () {
      this.bankAccount = {
        ...this.details
      }
    }
  },

  computed: mapState({
    details: state => state.bankAccounts.details,
    loading: state => state.bankAccounts.loading
  }),

  methods: {

    ...mapActions('bankAccounts', [
      'show',
      'update'
    ])

  },

  mounted () {
    this.show(this.$route.params.id)
  }

}

</script>
