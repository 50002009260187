<template>
    <router-link :to="'/articles/' + article.id" class="publication-box d-block">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <div class="img mr-4">
            <img :src="article.thumbnail" class="br-10" width="100px" alt="">
          </div>
          <div class="txt">
            <div class="d-flex justify-content-between">
              <h4 class="fw-600 fs-16 mb-0 description-nowrap c-black">{{ article.title }}</h4>
              <div class="d-flex align-items-center pl-5 nowrap">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path :fill="getStatusColor(article.status)" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
                <h4 class="fw-600 fs-16 mb-0 c-black ml-2">
                  {{ getStatus(article.status) }}
                  <span v-if="article.scheduled_at && article.status == 2"> para {{ article.scheduled_at | datetime }}</span>
                </h4>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-2">
              <p class="fw-400 fs-14 c-black-1 mb-0 description-nowrap">{{ article.description }}</p>
              <span class="fw-400 fs-14 mb-0 c-grey nowrap pl-5" >Criado em: {{ article.created_at | date }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
</template>
<script>
export default {
  props: {
    article: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data: function () {
    return {
      // article: {}
    }
  },
  methods: {
    getStatus (statusColor) {
      switch (parseInt(statusColor)) {
        case 0: return 'Inativo'
        case 1: return 'Ativo'
        case 2: return 'Agendado'
        default: return '#cccccc'
      }
    },
    getStatusColor (statusColor) {
      switch (parseInt(statusColor)) {
        case 0: return '#dc3545'
        case 1: return '#3DD598'
        case 2: return '#d9932e'
        default: return '#cccccc'
      }
    }
  },
  mounted () {
    // console.log(this.articles.data[0])
    // console.log(this.article)
  }
}
</script>
<style scoped>

.publication-box .txt {
  overflow: hidden;
  width: 100%;
}
.description-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nowrap {
  white-space: nowrap;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
</style>
