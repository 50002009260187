<template>
  <div class="block-hover">
    <div class="position-icon pointer">
      <svg @click="$emit('openDelete')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3 6H5H21" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="d-flex">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M5.99316 7.99675H5.99983V8.00341H5.99316V7.99675ZM5.99316 3.33008H5.99983V3.33674H5.99316V3.33008ZM5.99316 12.6634H5.99983V12.6701H5.99316V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
        <path d="M10 7.99675H10.0067V8.00341H10V7.99675ZM10 3.33008H10.0067V3.33674H10V3.33008ZM10 12.6634H10.0067V12.6701H10V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
      </svg>
      <button :class="['btn btn-blocks', button_block.align ? button_block.align : '']" @click="openButtonModal(button_block)">{{ button_block.text ? button_block.text : 'Texto do botão' }}</button>
    </div>

    <!-- Button Modal -->
    <div class="modal fade" :id="'buttonModal' + button_block.id" tabindex="-1" role="dialog" aria-labelledby="buttonModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">Botão</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <label for="">Texto*</label>
              <input type="text" placeholder="Texto do botão" v-model="button_block.text">
            </div>
            <div class="col-12 mt-2">
              <label for="">Alinhamento*</label>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <input type="radio" name="align" id="left" value="left" v-model="button_block.align">
                  <label for="left" class="radio-label">Esquerda</label>
                </div>
                <div class="d-flex align-items-center">
                  <input type="radio" name="align" id="right" value="right" v-model="button_block.align">
                  <label for="right" class="radio-label">Direita</label>
                </div>
                <div class="d-flex align-items-center">
                  <input type="radio" name="align" id="center" value="center" v-model="button_block.align">
                  <label for="center" class="radio-label">Centro</label>
                </div>
                <div class="d-flex align-items-center">
                  <input type="radio" name="align" id="block" value="block" v-model="button_block.align">
                  <label for="block" class="radio-label">Linha inteira</label>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <label for="">Links*</label>
              <button class="btn btn-collapse" type="button" data-toggle="collapse" data-target="#collapseLink" aria-expanded="false" aria-controls="collapseLink">
                <p class="mb-0">Links</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M4 6L8 10L12 6" stroke="#171725" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="collapse" id="collapseLink">
                <input type="text" placeholder="Inserir link externo" v-model="button_block.link">
                <div class="d-flex align-items-center">
                  <img src="/img/blog/grey-block.jpg" height="50px" class="br-10" alt="">
                  <p class="mb-0 ml-3">Nome do produto</p>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4" @click="setButton(button_block)">
              <button class="btn btn-blue my-3 my-sm-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_1_1170)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9549 4.25916C18.1893 4.49357 18.3209 4.81146 18.3209 5.14291C18.3209 5.47437 18.1893 5.79225 17.9549 6.02666L8.58577 15.3958C8.46196 15.5197 8.31496 15.6179 8.15317 15.6849C7.99139 15.752 7.81798 15.7865 7.64286 15.7865C7.46774 15.7865 7.29433 15.752 7.13254 15.6849C6.97076 15.6179 6.82376 15.5197 6.69994 15.3958L2.04494 10.7417C1.92555 10.6264 1.83032 10.4884 1.76481 10.3359C1.6993 10.1834 1.66482 10.0194 1.66338 9.85341C1.66194 9.68744 1.69356 9.52284 1.75641 9.36922C1.81926 9.2156 1.91208 9.07603 2.02945 8.95867C2.14681 8.8413 2.28638 8.74849 2.44 8.68563C2.59362 8.62278 2.75822 8.59116 2.92419 8.5926C3.09017 8.59404 3.25419 8.62852 3.4067 8.69403C3.5592 8.75955 3.69713 8.85477 3.81244 8.97416L7.64244 12.8042L16.1866 4.25916C16.3027 4.143 16.4405 4.05086 16.5922 3.98799C16.7439 3.92512 16.9066 3.89276 17.0708 3.89276C17.235 3.89276 17.3976 3.92512 17.5493 3.98799C17.701 4.05086 17.8389 4.143 17.9549 4.25916Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_1170">
                  <rect width="20" height="20" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                Salvar configuração
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    _button_block: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data: function () {
    return {
      button_block: {
        text: null,
        align: null
      }
    }
  },

  methods: {
    setButton (block) {
      this.button_block = block
      $('#buttonModal' + block.id).modal('hide')
    },
    openButtonModal (block) {
      this.button_block = block
      $('#buttonModal' + block.id).modal('show')
    }
  },
  mounted () {
    this.button_block = this._button_block
  }
}
</script>
<style scoped>
.btn-blocks {
  border-radius: 10px;
  background-color: #F2F7FF;
  color: #0062FF;
  min-height: 38px;
  display: block;
  padding: 8px 14px;
}
.btn-blocks.left {
  margin-left: 0;
}
.btn-blocks.right {
  margin-left: auto;
}
.btn-blocks.center {
  margin-left: auto;
  margin-right: auto;
}
.btn-blocks.block {
  width: 100%;
}

.modal-content {
  margin-top: 0 !important;
  border-radius: 5px;
  border: 0;
  padding: 16px;
}
    /* font-size: 28px; */
  input, select {
    font-weight: 600;
    border: 0;
    color: #000000;
    width: 100%;
  }
  input::placeholder {
    color: #000000;
  }
  .card-title {
    font-weight: 600;
    font-size: 16px;
  }
  label {
    display: block;
    color: #696974;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .radio-label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 2px;
    color: #000000;
    white-space: nowrap;
  }
.btn-collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.block-hover {
  position: relative;
}
.position-icon {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
  background: rgba(250, 250, 251, 0.75);
  padding: 8px;
  opacity: 0;
  transition: .3s;
}
.block-hover:hover .position-icon {
  opacity: 1;
  transition: .3s;
}
</style>
