var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{class:[
      'fas',
      'fa-dot-circle',
      'mr-2',
      _vm.order_status == 1 ? 'text-muted' : '',
      _vm.order_status == 2 ? 'text-warning' : '',
      _vm.order_status == 3 ? 'text-success' : '',
      _vm.order_status == 4 ? 'text-danger' : '',
    ]}),_vm._v(_vm._s(_vm.labelText())+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }