<template>
  <div class="orders">
    <div class="row">
      <div class="col">
        <div class="row justify-content-between">
          <div class="col-4">
            <h1 class="fw-600 fs-24 mb-0"> Recorrências </h1>
          </div>
          <div class="col-3 text-right text-end pt-2">
            <button class="btn btn-blue" @click="this.recurrence = {}" data-target="#recurrenceModal" data-toggle="modal">
              <i class="fas fa-plus mr-2"></i>Nova Recorrência
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table style="width: 1700px" class="table table-striped table-bordered">
            <thead>
              <tr class="t-head">
                <th style="width:50px" scope="col">
                  <div class="d-flex justify-content-between align-items-center">
                    Descrição
                    <div class="btn" data-target="#searchModal" data-toggle="modal">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </th>
                <th style="width:50px" scope="col" class="pb-3">
                  <p class="mb-0">Status</p>
                </th>
                <th style="width:50px" scope="col">
                  <div class="row">
                    <div class="col-md-6 justify-content-start d-flex align-items-center">
                      Desconto
                    </div>
                    <div class="col-md-6 justify-content-end d-flex align-items-center">
                      <div class="btn" @click="openFilterModal('')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:110px" scope="col">
                  <div class="row">
                    <div class="col-sm-9 justify-content-start d-flex align-items-center">
                      Período de Pagamento (dias)
                    </div>
                    <div class="col-sm-3 justify-content-end d-flex align-items-center" >
                      <div class="btn" @click="openFilterModal('')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:110px" scope="col">
                  <div class="row">
                    <div class="col-sm-9 justify-content-start d-flex align-items-center">
                      Período de Envio (dias)
                    </div>
                    <div class="col-sm-3 justify-content-end d-flex align-items-center" >
                      <div class="btn" @click="openFilterModal('')">
                      <i class="fas fa-filter"></i>
                      </div>
                    </div>
                  </div>
                </th>
                <th style="width:30px" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="recurrence in recurrences.data" :key="recurrence.id">
                <td class="align-middle body-table pointer">
                  {{recurrence.description}}
                </td>
                <td class="align-middle body-table">{{ setStatus(recurrence.status) }}</td>
                <td class="align-middle body-table">{{ recurrence.discount | percentage }}</td>
                <td class="align-middle body-table">{{ recurrence.frequence_payment_days }}</td>
                <td class="align-middle body-table">{{ recurrence.frequence_shipping_days }}</td>
                <td class="align-middle body-table px-0">
                  <button v-if="recurrence.user_recurrences_count > 0" class="btn mr-2" data-toggle="modal" @click="editRecurrenceModal(recurrence)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button v-if="recurrence.user_recurrences_count == 0" class="btn ml-4" @click="openDeleteModal(recurrence)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginateComponent :source="pagination" @navigate="navigate"/>
      </div>
    </div>
    <LoadingComponent :loading="loading" />

    <!-- Modal -->
    <div class="modal fade" tabindex="-1" id="recurrenceModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ recurrence.id ? 'Editar Recorrência' : 'Criar Recorrência' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <label for="description" class="form-control-label">Descrição</label>
                <input type="text" v-model="recurrence.description" required id="description" placeholder="Digite aqui..." class="form-control">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <div class="input-group">
                  <label for="discount" class="form-control-label w-100">Desconto:</label>
                  <money class="form-control" id="discount" required v-model="recurrence.discount" v-bind="percentage"></money>
                </div>
              </div>
              <div class="col-6">
                <label for="status" class="form-control-label">Status</label>
                <select id="status" class="form-control slt-opt" required v-model="recurrence.status">
                  <option :value="null">Selecione</option>
                  <option :value="1">Ativo</option>
                  <option :value="0">Inativo</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="frequence_payment_days" class="form-control-label">Período Pagamento (dias)</label>
                <input type="number" required v-model="recurrence.frequence_payment_days" id="frequence_payment_days" placeholder="Ex: 360 dias" class="form-control">
              </div>
              <div class="col-6">
                <label for="frequence_shipping_days" class="form-control-label">Período de Envio (dias)</label>
                <input type="number" required v-model="recurrence.frequence_shipping_days" id="frequence_shipping_days" placeholder="Ex: 30 dias" class="form-control">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger px-3" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success px-4" @click="recurrence.id ? update(recurrence) : store(recurrence)" data-dismiss="modal">Salvar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Filtro -->
    <div class="modal fade" tabindex="-1" id="filterModal" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filtrar Listagem</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-warning" @click="index({ page: 1, search, filter})" data-dismiss="modal">Filtrar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal excluir recorrencia -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row text-white">
              <div class="col-12 d-flex justify-content-end mb-3">
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="col-12 text-center pb-4 pt-1">
                <label class="form-control-label fs-16">Deseja excluir a recorrência <b>{{ recurrence.description }}</b>?</label>
              </div>
              <div class="col-12 text-center">
                <button type="button" class="btn btn-outline-danger px-4" @click="destroy(recurrence.id)">Excluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent.vue'
import Vue from 'vue'
Vue.filter('percentage', function (value) {
  if (value === 'Erro') return value
  const val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' %'
})

export default {

  name: 'IndexRecurrencesPage',

  components: {
    LoadingComponent
  },

  computed: mapState({
    recurrences: state => state.recurrences.data,
    pagination: state => state.recurrences.pagination,
    loading: state => state.recurrences.loading
  }),

  data: function () {
    return {
      search: '',
      filter: {},
      recurrence: {
        status: null
      },
      percentage: {
        suffix: ' %',
        decimal: '.',
        thousands: '',
        precision: 2,
        masked: false
      }
    }
  },

  methods: {
    ...mapActions('recurrences', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    editRecurrenceModal (recurrence) {
      this.recurrence = {
        ...recurrence
      }

      $('#recurrenceModal').modal('show')
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    setStatus (status) {
      switch (parseInt(status)) {
        case 1: return 'Ativo'
        case 2: return 'Inativo'
        default: return '-'
      }
    },

    openDeleteModal (recurrence) {
      this.recurrence = {}
      this.recurrence = recurrence
      $('#deleteModal').modal('show')
    }
  },

  mounted () {
    this.index({ page: 1 })
  }

}

</script>
