<template>
  <div class="colors">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Cores dos Produtos</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Nova Cor
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Abreviação</th>
                <th>Código</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="color in colors.data" :key="color.id">
                <td class="align-middle body-table">
                    {{ color.id }}
                </td>
                <th class="align-middle body-table">
                    {{ color.description }}
                </th>
                <td class="align-middle body-table">
                  {{ color.abbreviation }}
                </td>
                <td class="align-middle body-table">
                  {{ color.hex_code }}
                </td>
                <td class="align-middle body-table">
                  <button v-if="color.company_id" class="btn mr-2" data-toggle="modal" @click="openUpdateModal(color)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button v-if="color.company_id" class="btn" data-toggle="modal" @click="openDeleteModal(color)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !colors.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (color.id ? 'Editar Cor' : 'Cadastrar Cor')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="color.description" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Abreviação:</label>
                    <input type="text" class="form-control" v-model="color.abbreviation" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Código:</label>
                    <input type="text" class="form-control" v-model="color.hex_code" required>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateColor()" v-if="color.id">Editar Cor</button>
            <button type="button" class="btn btn-success" @click="addColor()" v-else>Cadastrar Cor</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
    <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">Excluir Cor</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Deseja excluir o Cor?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                    <button type="button" class="btn btn-danger" @click="deleteColor()">Excluir</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexcolorsPage',

  components: {
    PaginateComponent,
    LoadingComponent
  },

  computed: mapState({
    colors: state => state.colors.data,
    pagination: state => state.colors.pagination,
    loading: state => state.colors.loading
  }),

  data: function () {
    return {
      color: {
        description: null,
        abbreviation: null,
        hex_code: null
      },
      destroy_color: null
    }
  },

  methods: {

    ...mapActions('colors', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    addColor: function () {
      this.store(this.color)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.color = {
        description: null,
        abbreviation: null,
        hex_code: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (color) {
      this.color = color
      $('#CreateModal').modal()
    },

    openDeleteModal: function (color) {
      this.destroy_color = color
      $('#openDeleteModal').modal()
    },

    updateColor: function () {
      this.update({ id: this.color.id, data: this.color })
      $('#CreateModal').modal('hide')
    },

    deleteColor: function () {
      this.destroy(this.destroy_color.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
