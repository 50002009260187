<template>
  <div class="preferencess">
    <div class="row">
      <div class="col">
        <h1 class="fw-600 fs-24 mb-0">Configurações da Conta</h1>
      </div>
    </div>
    <hr>
    <FormPreferences :_preferences="preferences" @update="update" />
    <LoadingComponent :loading="loading" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import FormPreferences from './FormPreferences'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'ShowPreferencesPage',

  components: {
    LoadingComponent,
    FormPreferences
  },

  data () {
    return {
      preferences: {}
    }
  },

  watch: {
    data () {
      this.preferences = {
        ...this.data
      }
    }
  },

  computed: mapState({
    data: state => state.preferences.data,
    loading: state => state.preferences.loading
  }),

  methods: {

    ...mapActions('preferences', [
      'show',
      'update'
    ])

  },

  mounted () {
    this.show()
  }

}

</script>
