import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', function (value) {
  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('datetime', function (value) {
  return moment(value).format('DD/MM/YYYY - HH:mm')
})

Vue.filter('money', function (value) {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('payment_type', function (value) {
  let text
  if (value === undefined) {
    text = 'N/A'
    return text
  }
  switch (value) {
    case 1:
      text = 'Boleto'
      break
    case 2:
      text = 'Cartão de Crédito'
      break
    case 3:
      text = 'Pix'
      break
    case 4:
      text = 'Cartão de Débito'
      break
    case 5:
      text = 'Dinheiro'
      break
    default:
      text = 'N/A'
  }
  return text
})
