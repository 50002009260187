import Vue from 'vue'
import { store, edit } from '../../services/shippings/shippings'

export const shippings = {

  namespaced: 'shippings',

  state: {
    data: {},
    loading: true,
    error: null
  },

  actions: {
    store ({ state, commit }, data) {
      if (!data.shipping_code && data.shipping_code === 1) {
        commit('setError', 'Este campo é obrigatório')
        return false
      }
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success(response.data)
          setTimeout(() => {
            window.location.reload(1)
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar código de rastreio!')
        })
    },
    edit ({ state, commit }, data) {
      commit('setLoading', true)
      edit(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('Status alterado com sucesso')
          setTimeout(() => {
            window.location.reload(1)
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar código de rastreio!')
        })
    }
  },

  mutations: {

    setData (state, shippings) {
      state.data = shippings
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      state.error = error
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
