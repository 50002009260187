<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <textarea v-model="text_midia.text" class="w-100 form-control" rows="1"></textarea>
      </div>
      <div class="col-md-6">
        <div
          class="drop-area"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
          :class="{ active: isDragging }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.66683 10.6667H9.3335C9.70016 10.6667 10.0002 10.3667 10.0002 10V6.66667H11.0602C11.6535 6.66667 11.9535 5.94667 11.5335 5.52667L8.4735 2.46667C8.41182 2.40487 8.33856 2.35583 8.25791 2.32238C8.17726 2.28893 8.09081 2.27171 8.0035 2.27171C7.91618 2.27171 7.82973 2.28893 7.74908 2.32238C7.66843 2.35583 7.59517 2.40487 7.5335 2.46667L4.4735 5.52667C4.0535 5.94667 4.34683 6.66667 4.94016 6.66667H6.00016V10C6.00016 10.3667 6.30016 10.6667 6.66683 10.6667ZM4.00016 12H12.0002C12.3668 12 12.6668 12.3 12.6668 12.6667C12.6668 13.0333 12.3668 13.3333 12.0002 13.3333H4.00016C3.6335 13.3333 3.3335 13.0333 3.3335 12.6667C3.3335 12.3 3.6335 12 4.00016 12Z" fill="#0062FF"/>
          </svg>
          <p class="fs-14 fw-400 mb-0 c-grey">Arraste arquivos aqui para fazer Upload de arquivos de mídia</p>
          <p class="fs-10 fw-400 mb-0 c-grey">Imagens e Vídeos de até 50MG</p>
        </div>
        <input
          type="file"
          class="input-file"
          ref="fileInput"
          multiple
          @change="handleFileInput"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    _text_midia: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data: function () {
    return {
      text_midia: {},
      isDragging: false,
      selectedFiles: []
    }
  },

  methods: {
    handleDragOver (event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave () {
      this.isDragging = false
    },
    handleDrop (event) {
      event.preventDefault()
      this.isDragging = false

      const files = event.dataTransfer.files
      this.addFilesToArray(files)
    },
    handleFileInput (event) {
      const files = event.target.files
      this.addFilesToArray(files)
    },
    addFilesToArray (files) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i])
      }
      console.log(this.selectedFiles)
      this.text_midia.content = this.selectedFiles
    },
    setMidia () {
      // this.button_block.content.push(this.button)
      this.midia_block.content = this.button
      // // this.$emit('button', this.button)
      // $('#buttonModal').modal('hide')
    }
  },

  mounted () {
    this.text_midia = this._text_midia
  }
}
</script>

<style scoped>
.drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  font-size: 18px;
  color: #999;
}

.active {
  background-color: #f0f0f0;
}
</style>
