
<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-color-black fixed">
    <a class="navbar-brand" href="#" id="sidebarCollapse" >
      <img :src="preferences.data.avatar" alt="" height="40">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto d-none">
          <li>
            <div class="input-group pl-3">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90226 13.6976C-0.0787466 10.7166 -0.0787466 5.88342 2.90226 2.90242C5.88326 -0.0785863 10.7164 -0.0785863 13.6974 2.90242C16.4222 5.62721 16.6564 9.89947 14.4 12.8903L17.4118 15.9021C18.1975 16.6877 17.0189 17.8662 16.2333 17.0806L13.2575 14.1048C10.2584 16.6718 5.74071 16.536 2.90226 13.6976ZM12.5189 4.08093C10.1888 1.7508 6.41089 1.7508 4.08077 4.08093C1.75064 6.41106 1.75064 10.1889 4.08077 12.5191C6.41089 14.8492 10.1888 14.8492 12.5189 12.5191C14.849 10.1889 14.849 6.41106 12.5189 4.08093Z" fill="#92929D"/>
              </svg>
              <input type="text" class="form-control" placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="basic-addon2">
            </div>
          </li>
      </ul>
      <ul class="navbar-nav mr-4 ml-auto d-flex align-items-center">
        <li class="nav-item mr-4">
          <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.98 2.64094C11.8026 1.15337 10.5367 0 9.0013 0C7.46596 0 6.2 1.15337 6.02257 2.64094C3.5199 3.77546 1.77908 6.29555 1.77908 9.22222V14.2399L0.108582 17.5494C-0.227141 18.2145 0.25626 19 1.0013 19H6.17201C6.58385 20.1652 7.69509 21 9.0013 21C10.3075 21 11.4188 20.1652 11.8306 19H17.0013C17.7463 19 18.2297 18.2145 17.894 17.5494L16.2235 14.2399V9.22222C16.2235 6.29555 14.4827 3.77546 11.98 2.64094ZM14.3308 14.9286L15.3764 17H2.62623L3.6718 14.9286C3.74234 14.7888 3.77908 14.6345 3.77908 14.478V9.22222C3.77908 6.33807 6.11715 4 9.0013 4C11.8855 4 14.2235 6.33807 14.2235 9.22222V14.478C14.2235 14.6345 14.2603 14.7888 14.3308 14.9286Z" fill="#92929D"/>
          </svg>
        </li>
        <li class="nav-item">
          <div class="btn-group">
            <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div>
                <p class="fs-14 fw-600 c-black mb-0 mr-3">{{user.name}}</p>
                <p class="fs-12 fw-400 c-grey mb-0 text-left">{{setUserType(user.type)}}</p>
              </div>
              <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29269 0.333336C9.71195 0.333336 9.94504 0.818302 9.68313 1.14568L5.39011 6.51196C5.18995 6.76216 4.8094 6.76216 4.60924 6.51196L0.316218 1.14568C0.0543127 0.818301 0.2874 0.333336 0.706654 0.333336L9.29269 0.333336Z" fill="#92929D"/>
              </svg>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <!-- <button class="dropdown-item" type="button">Action</button>
              <button class="dropdown-item" type="button">Another action</button> -->
              <router-link class="dropdown-item text-dark" to="/bank-accounts">Dados Bancários</router-link>
              <router-link class="dropdown-item text-dark" to="/preferences">Preferências</router-link>
              <button class="dropdown-item" @click="logoutEvent()" type="button">Sair</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {

  name: 'NavbarPage',

  data: function () {
    return {
      user: {}
    }
  },

  computed: mapState({
    preferences: state => state.preferences
  }),

  methods: {

    ...mapActions('auth', [
      'logout'
    ]),

    logoutEvent: function () {
      this.logout()
    },

    setUserType: function (type) {
      switch (type) {
        case 1: return 'Master'
        case 2: return 'Clientes'
        default:
          break
      }
    }

  },

  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'))
    console.log(this.user)
  }
}
</script>

<style>

</style>
