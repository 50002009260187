<template>
  <div class="sessions">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Sessões</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Nova Sessão
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Ordem</th>
                <th>Nome</th>
                <th>Url</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="session in sessions.data" :key="session.id">
                <td class="align-middle body-table">
                    {{ session.id }}
                </td>
                <td class="align-middle body-table">
                    {{ session.order }}
                </td>
                <th class="align-middle body-table">
                    {{ session.title }}
                </th>
                <td class="align-middle body-table">
                  {{ (session.url) ? session.url : '-' }}
                </td>
                <td class="align-middle body-table">
                  <button class="btn mr-2" data-toggle="modal" @click="openUpdateModal(session)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn" data-toggle="modal" @click="openDeleteModal(session)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !sessions.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (session.id ? 'Editar sessão' : 'Cadastrar sessão')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-12">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="session.title" required>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <label class="form-control-label">Descrição:</label>
                    <input type="text" class="form-control" v-model="session.description" required>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <label class="form-control-label">Posição:</label>
                    <input type="number" class="form-control" v-model="session.order" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Link de Destino:</label>
                    <input type="text" class="form-control" v-model="session.url" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Relação:</label>
                    <select class="form-control" v-model="session.relation" required>
                      <option :value="null"> - Selecione - </option>/
                      <option :value="1">Produto</option>
                      <!-- <option :value="'banner'">Banner</option> -->
                      <!-- <option :value="'blog'">Blog</option> -->
                    </select>
                  </div>
                </div>
                <div class="row mt-4" v-if="session.relation == 1">
                  <div class="col-md-12 my-3">
                    <p class="py-0 my-0">Produtos</p>
                  </div>
                  <div v-for="product in products" :key="product.id" class="col-md">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input type="checkbox" v-model="session.products_ids" :value="product.id" :id="'optionProduct'+product.id" class="form-check-input">{{ product.title }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateSession()" v-if="session.id">Editar sessão</button>
            <button type="button" class="btn btn-success" @click="addSession()" v-else>Cadastrar sessão</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir sessão</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o sessão?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteSession()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'IndexSessionsPage',

  components: {
    LoadingComponent
  },

  computed: mapState({
    products: state => state.params.data.products,
    sessions: state => state.sessions.data,
    loading: state => state.sessions.loading
  }),

  data: function () {
    return {
      session: {
        description: null,
        slug: null,
        parent_id: null,
        coupon_id: null
      },
      destroy_session: null
    }
  },

  methods: {

    ...mapActions('params', [
      'getProducts'
    ]),

    ...mapActions('sessions', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    addSession: function () {
      this.store(this.session)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.getProducts()
      this.session = {
        title: null,
        description: null,
        url: null,
        order: null,
        relation: null,
        products_ids: []
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (session) {
      this.getProducts()
      this.session = session
      $('#CreateModal').modal()
    },

    openDeleteModal: function (session) {
      this.destroy_session = session
      $('#openDeleteModal').modal()
    },

    updateSession: function () {
      this.update({ id: this.session.id, data: this.session })
      $('#CreateModal').modal('hide')
    },

    deleteSession: function () {
      this.destroy(this.destroy_session.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
