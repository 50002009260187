import { index, store, update, destroy, show, findCategories, getBlogBreadcrumbs } from '../../services/blogs/categories'
import Vue from 'vue'
export const blogCategories = {

  namespaced: 'blogs',

  state: {
    data: {},
    details: {},
    breadcrumbs: [],
    loading: true,
    resetSearch: null,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.categories)
          commit('cleanBreadcrumbs')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('Categoria cadastrada com sucesso!')
          if (response.data.category.parent_id) {
            dispatch('show', response.data.category.parent_id)
          } else {
            dispatch('index', { page: 1 })
          }
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a categoria!')
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setLoading', false)
          Vue.toasted.success('Categoria editada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a categoria!')
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      destroy(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('Categoria excluída com sucesso!')
          if (state.details.id) {
            dispatch('show', state.details.id)
          } else {
            dispatch('index', { page: 1 })
          }
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a categoria!')
        })
    },

    show ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setData', response.data.category.subcategories)
          commit('setBreadcrumbs', response.data.category)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    findCategories ({ state, commit }, search) {
      commit('setLoading', true)
      findCategories(search)
        .then(response => {
          commit('setLoading', false)
          commit('setData', response.data.categories)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    getBlogBreadcrumbs ({ state, commit }, articleId) {
      commit('setLoading', true)
      getBlogBreadcrumbs(articleId)
        .then(response => {
          commit('setLoading', false)
          commit('setBreadcrumbFind', response.data.breadcrumbs)
          this.show(articleId)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    setBreadcrumbs ({ state, commit }, breadcrumbsSearch) {
      commit('cleanBreadcrumbs')
      commit('setBreadcrumbFind', breadcrumbsSearch)
      commit('resetSearch')
    },

    back ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setDetails', response.data.category)
          commit('setData', response.data.category.subcategories)
          commit('undoBreadcrumbs', response.data.category)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }

  },

  mutations: {

    setData (state, categories) {
      state.data = categories
    },

    setDetails (state, category) {
      state.details = category
    },

    setBreadcrumbs (state, category) {
      if (state.breadcrumbs.length === 0 || state.breadcrumbs[state.breadcrumbs.length - 1].id !== category.id) {
        state.breadcrumbs.push(category)
      }
    },

    setBreadcrumbFind (state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },

    cleanBreadcrumbs (state) {
      state.breadcrumbs = []
    },

    undoBreadcrumbs (state) {
      state.breadcrumbs.pop()
    },

    setLoading (state, status) {
      state.loading = status
    },

    resetSearch (state) {
      state.resetSearch = 'reset'
    },

    setError (state, error) {
      if (error && error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
