import Vue from 'vue'
import { index, show, excel, update } from '../../services/orders/orders'

export const orders = {

  namespaced: 'orders',

  state: {
    data: {},
    pagination: {},
    details: {},
    download: [],
    company_name: '',
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, data) {
      index(data.page, data.search, data.filter)
        .then(response => {
          commit('setData', response.data.orders)
          commit('setPagination', response.data.orders)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    show ({ state, commit }, id) {
      show(id)
        .then(response => {
          commit('setDetails', response.data.order)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit }, { id, data }) {
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.order)
          commit('setLoading', false)
          Vue.toasted.success('Pedido atualizado com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar pedido!')
        })
    },

    excel ({ state, commit }, { from, to, products }) {
      excel(from, to, products)
        .then(response => {
          commit('setDownload', response.data)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    setOrderExcelParams ({ state, commit }) {
      state.download = []
      state.company_name = ''
    }
  },

  mutations: {

    setData (state, orders) {
      state.data = orders
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, order) {
      state.details = order
    },

    setLoading (state, status) {
      state.loading = status
    },

    setDownload (state, orders) {
      state.download = orders.report
      state.company_name = orders.company_name
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
