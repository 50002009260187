import Http from '../http'

export const getCoupons = (page) => Http.get(`/params/coupons?page=${page}`, { headers: { Authorization: localStorage.token } })
export const getProducts = (status = null) => {
  const url = status ? `/params/products?status=${status}` : '/params/products'
  return Http.get(url, { headers: { Authorization: localStorage.token } })
}
export const getColors = () => Http.get('/params/colors', { headers: { Authorization: localStorage.token } })
export const getMaterials = () => Http.get('/params/materials', { headers: { Authorization: localStorage.token } })
export const getSizes = () => Http.get('/params/sizes', { headers: { Authorization: localStorage.token } })
export const getBanks = () => Http.get('/params/banks', { headers: { Authorization: localStorage.token } })
export const getCategories = (page) => Http.get(`/params/categories?page=${page}`, { headers: { Authorization: localStorage.token } })
export const showCategory = (id) => Http.get(`/params/categories/${id}`, { headers: { Authorization: localStorage.token } })
export const getUserTypes = () => Http.get('/params/user_types', { headers: { Authorization: localStorage.token } })
