<template>
  <div class="users">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Usuários</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Novo Usuário
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Tipo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users.data" :key="user.id">
                <td class="align-middle body-table">
                    {{ user.id }}
                </td>
                <th class="align-middle body-table">
                    {{ user.name }}
                </th>
                <td class="align-middle body-table">
                  {{ user.email }}
                </td>
                <td class="align-middle body-table">
                  {{ user.user_type.description }}
                </td>
                <td class="align-middle body-table">
                  <button v-if="user.company_id" class="btn mr-2" data-toggle="modal" @click="openUpdateModal(user)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button v-if="user.company_id" class="btn" data-toggle="modal" @click="openDeleteModal(user)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !users.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (user.id ? 'Editar Usuário' : 'Cadastrar Usuário')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="user.name" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">E-mail:</label>
                    <input type="text" class="form-control" v-model="user.email" required>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Tipo:</label>
                    <select v-model="user.user_type_id" class="form-control">
                      <option :value="null">- Selecione -</option>
                      <option v-for="userType in user_types" :key="userType.id" :value="userType.id">{{ userType.description }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateuser()" v-if="user.id">Editar Usuário</button>
            <button type="button" class="btn btn-success" @click="adduser()" v-else>Cadastrar Usuário</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
    <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">Excluir Usuário</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Deseja excluir o Usuário?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                    <button type="button" class="btn btn-danger" @click="deleteuser()">Excluir</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexUsersPage',

  components: {
    PaginateComponent,
    LoadingComponent
  },

  computed: mapState({
    user_types: state => state.params.data.user_types,
    users: state => state.users.data,
    pagination: state => state.users.pagination,
    loading: state => state.users.loading
  }),

  data: function () {
    return {
      user: {
        name: null,
        email: null,
        user_type_id: null
      },
      destroy_user: null
    }
  },

  methods: {

    ...mapActions('users', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    ...mapActions('params', [
      'getUserTypes'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    adduser: function () {
      this.store(this.user)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.user = {
        name: null,
        email: null,
        user_type_id: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (user) {
      this.user = user
      $('#CreateModal').modal()
    },

    openDeleteModal: function (user) {
      this.destroy_user = user
      $('#openDeleteModal').modal()
    },

    updateuser: function () {
      this.update({ id: this.user.id, data: this.user })
      $('#CreateModal').modal('hide')
    },

    deleteuser: function () {
      this.destroy(this.destroy_user.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
    this.getUserTypes()
  }

}

</script>
