<template>
  <form @submit.prevent="save()">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 pb-3">
            <label for="bank_acount_bank_id" class="form-control-label">Banco:</label>
            <select id="bank_acount_bank_id" class="form-control slt-opt" v-model="data.param_bank_id">
                <option :value="null">Selecione</option>
                <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{ bank.code }} - {{ bank.name }}</option>
            </select>
          </div>
          <div class="col-md-4 pb-3">
              <label for="bank_account_holder_name" class="form-control-label">Titular:</label>
              <input type="text" id="bank_account_holder_name" v-model="data.holder_name" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
              <label for="bank_account_holder_document" class="form-control-label">Documento:</label>
              <input type="text" id="bank_account_holder_document" v-model="data.holder_document" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_agency_number" class="form-control-label">Agência:</label>
              <input type="text" id="bank_account_agency_number" v-model="data.agency_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_agency_digit_number" class="form-control-label">Dígito:</label>
              <input type="text" id="bank_account_agency_digit_number" v-model="data.agency_digit_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_account_number" class="form-control-label">Conta:</label>
              <input type="text" id="bank_account_account_number" v-model="data.account_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_account_digit_number" class="form-control-label">Dígito:</label>
              <input type="text" id="bank_account_account_digit_number" v-model="data.account_digit_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
            <label for="bank_acount_bank_id" class="form-control-label">Tipo de Conta:</label>
            <select id="bank_acount_bank_id" class="form-control slt-opt" v-model="data.type">
                <option :value="null">Selecione</option>
                <option :value="'checking'">Corrente</option>
                <option :value="'saving'">Poupança</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success mt-3">
      Salvar Alterações
    </button>
  </form>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

  props: ['_bankAccount'],

  watch: {
    _bankAccount () {
      this.data = {
        ...this.data,
        ...this._bankAccount
      }
    }
  },

  data () {
    return {
      data: {
        param_bank_id: null,
        type: null
      },
      errors: {},
      img: null,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      measure: {
        decimal: ',',
        thousands: '',
        precision: 2
      }
    }
  },

  computed: mapState({
    banks: state => state.params.data.banks,
    loading: state => state.params.loading
  }),

  methods: {

    ...mapActions('params', [
      'getBanks'
    ]),

    save () {
      if (this.data.id) {
        this.$emit('update', { id: this.$route.params.id, data: this.data })
      } else {
        this.$emit('store', this.data)
      }
    }

  },

  mounted () {
    this.data = {
      ...this._bankAccount
    }
    this.getBanks()
  }

}
</script>
